<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Cadastre-se 🚀 </b-card-title>
          <b-card-text class="mb-2">
            Ao se cadastrar, seu acesso será liberado após a aprovação do
            administrador.
          </b-card-text>
          <b-form class="auth-register-form mt-2" @submit.prevent="register">
            <!-- full_name -->
            <b-form-group label="Nome completo" label-for="full_name">
              <b-form-input
                id="full_name"
                v-model="full_name"
                name="full_name"
                :state="errors.length > 0 ? false : null"
                placeholder="Pedro Álvares"
              />
            </b-form-group>

            <b-form-group label="CPF" label-for="cpf">
              <b-form-input
                id="cpf"
                v-model="cpf"
                name="cpf"
                placeholder="000.000.000-00"
              />
            </b-form-group>

            <!-- password -->
            <b-form-group label-for="senha" label="Senha">
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="senha"
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="senha"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                Aceito os <b-link>termos de privacidade</b-link>
              </b-form-checkbox>
            </b-form-group>

            <b-button variant="primary" block type="submit">
              Cadastrar
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <span>Você já é cadastrado?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Sim! Quero entrar!</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">or</div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      user: {
        cpf: "",
        password: "",
      },
      username: "",
      userEmail: "",
      password: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          useJwt
            .register({
              username: this.username,
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              useJwt.setToken(response.data.accessToken);
              useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem(
                'userData',
                JSON.stringify(response.data.userData)
              );
              this.$ability.update(response.data.userData.ability);
              this.$router.push("/");
            })
            .catch((error) => {
              this.$refs.registerForm.setErrors(error.response.data.error);
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
