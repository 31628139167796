var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth-wrapper auth-v2" },
    [
      _c(
        "b-row",
        { staticClass: "auth-inner m-0" },
        [
          _c("b-link", { staticClass: "brand-logo" }, [_c("vuexy-logo")], 1),
          _c(
            "b-col",
            {
              staticClass: "d-none d-lg-flex align-items-center p-5",
              attrs: { lg: "8" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-lg-flex align-items-center justify-content-center px-5",
                },
                [
                  _c("b-img", {
                    attrs: { fluid: "", src: _vm.imgUrl, alt: "Register V2" },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center auth-bg px-2 p-lg-5",
              attrs: { lg: "4" },
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "px-xl-2 mx-auto",
                  attrs: { sm: "8", md: "6", lg: "12" },
                },
                [
                  _c("b-card-title", { staticClass: "mb-1" }, [
                    _vm._v(" Cadastre-se 🚀 "),
                  ]),
                  _c("b-card-text", { staticClass: "mb-2" }, [
                    _vm._v(
                      " Ao se cadastrar, seu acesso será liberado após a aprovação do administrador. "
                    ),
                  ]),
                  _c(
                    "b-form",
                    {
                      staticClass: "auth-register-form mt-2",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.register.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Nome completo",
                            "label-for": "full_name",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "full_name",
                              name: "full_name",
                              state: _vm.errors.length > 0 ? false : null,
                              placeholder: "Pedro Álvares",
                            },
                            model: {
                              value: _vm.full_name,
                              callback: function ($$v) {
                                _vm.full_name = $$v
                              },
                              expression: "full_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { label: "CPF", "label-for": "cpf" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "cpf",
                              name: "cpf",
                              placeholder: "000.000.000-00",
                            },
                            model: {
                              value: _vm.cpf,
                              callback: function ($$v) {
                                _vm.cpf = $$v
                              },
                              expression: "cpf",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { "label-for": "senha", label: "Senha" } },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "input-group-merge" },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control-merge",
                                attrs: {
                                  id: "senha",
                                  type: _vm.passwordFieldType,
                                  name: "senha",
                                  placeholder: "············",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                { attrs: { "is-text": "" } },
                                [
                                  _c("feather-icon", {
                                    staticClass: "cursor-pointer",
                                    attrs: { icon: _vm.passwordToggleIcon },
                                    on: { click: _vm.togglePasswordVisibility },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "register-privacy-policy",
                                name: "checkbox-1",
                              },
                              model: {
                                value: _vm.status,
                                callback: function ($$v) {
                                  _vm.status = $$v
                                },
                                expression: "status",
                              },
                            },
                            [
                              _vm._v(" Aceito os "),
                              _c("b-link", [_vm._v("termos de privacidade")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            block: "",
                            type: "submit",
                          },
                        },
                        [_vm._v(" Cadastrar ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    { staticClass: "text-center mt-2" },
                    [
                      _c("span", [_vm._v("Você já é cadastrado?")]),
                      _c("b-link", { attrs: { to: { name: "auth-login" } } }, [
                        _c("span", [_vm._v(" Sim! Quero entrar!")]),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "divider my-2" }, [
                    _c("div", { staticClass: "divider-text" }, [_vm._v("or")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "auth-footer-btn d-flex justify-content-center",
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "facebook",
                            href: "javascript:void(0)",
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "FacebookIcon" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "twitter",
                            href: "javascript:void(0)",
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "TwitterIcon" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "google",
                            href: "javascript:void(0)",
                          },
                        },
                        [_c("feather-icon", { attrs: { icon: "MailIcon" } })],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "github",
                            href: "javascript:void(0)",
                          },
                        },
                        [_c("feather-icon", { attrs: { icon: "GithubIcon" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }